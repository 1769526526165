import React, { useEffect, useState, createRef } from "react";

import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineClockCircle,
  AiOutlineCalendar,
} from "react-icons/ai";
import {
  Flex,
  Text,
  Icon,
  IconButton,
  Stack,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { addDays, subDays, format, parse } from "date-fns";

import { Loading, Header } from "../../components";
import { db } from "../../services/firebase";

import notFoundImg from "../../assets/images/not_found.png";

function Home() {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const headerRef = createRef();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [date, setDate] = useState(new Date());
  const [dateFilter, setDateFilter] = useState();

  useEffect(() => {
    loadList();
  }, [date]);

  const loadList = async () => {
    setLoading(true);
    const q = query(collection(db, "agenda"), where("data", "==", maskDate()));
    const querySnapshot = await getDocs(q);
    const newList = [];
    querySnapshot.forEach((doc) => {
      newList.push({ id: doc.id, ...doc.data() });
    });
    setLoading(false);
    setList(newList);
  };

  const maskDate = () => {
    try {
      return format(date, "dd/MM/yyyy");
    } catch (error) {
      return "";
    }
  };

  const handleDateFilterConfirm = () => {
    if (dateFilter) {
      try {
        setDate(parse(dateFilter, "yyyy-MM-dd", new Date()));
      } catch (error) {}
    }
    onClose();
  };

  console.log("headerRef", headerRef);

  return (
    <Flex flex="1" flexDir="column">
      <Header
        ref={headerRef}
        isFilter
        onFilter={(data) => setListFilter(data)}
        listFilter={listFilter}
      />
      {listFilter.length > 0 ? (
        <Flex flex="1" flexDir="column" px={["5", "10"]} py="2" pb="90px">
          <Button
            mt="20px"
            size="sm"
            colorScheme="blue"
            onClick={() => {
              setListFilter([]);
              loadList();
              headerRef?.current?.clearFilter();
            }}
          >
            Limpar filtro
          </Button>
          <Stack my="30px">
            {listFilter.map((item) => (
              <ItemList key={item?.id} item={item} isFilter />
            ))}
          </Stack>
        </Flex>
      ) : (
        <Flex flex="1" flexDir="column" px={["5", "10"]} py="2" pb="90px">
          <Flex align="center" justify="space-between" mt="20px">
            <IconButton
              icon={<Icon as={AiOutlineLeft} />}
              size="xs"
              onClick={() => setDate(subDays(date, 1))}
            />
            <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
              <PopoverTrigger>
                <Flex align="center" cursor="pointer">
                  <Text fontWeight="500" textAlign="center">
                    {maskDate()}
                  </Text>
                  <Icon as={AiOutlineCalendar} ml="5px" fontSize="17px" />
                </Flex>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Selecionar data</PopoverHeader>
                <PopoverBody>
                  <Flex flexDir="column">
                    <Input
                      type="date"
                      value={dateFilter}
                      onChange={(e) => setDateFilter(e?.target?.value)}
                    />
                    <Flex justify="center" mt="15px">
                      <Button
                        isDisabled={!dateFilter}
                        size="sm"
                        colorScheme="linkedin"
                        onClick={handleDateFilterConfirm}
                      >
                        Confirmar
                      </Button>
                    </Flex>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>

            <IconButton
              icon={<Icon as={AiOutlineRight} />}
              size="xs"
              onClick={() => setDate(addDays(date, 1))}
            />
          </Flex>

          {loading && <Loading />}
          {!loading && list.length > 0 && (
            <Stack my="30px">
              {list.map((item) => (
                <ItemList key={item?.id} item={item} />
              ))}
            </Stack>
          )}
          {!loading && list.length === 0 && (
            <Flex flex="1" align="center" justify="center" flexDir="column">
              <Image src={notFoundImg} boxSize="150px" />
              <Text textAlign="center" fontWeight="600" mt="15px">
                Nenhuma programação para essa data.
              </Text>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
}

const ItemList = ({ item, isFilter }) => (
  <Flex boxShadow="lg" borderRadius="5px">
    <Flex w="10px" bg={item?.cor || "blue.500"} borderLeftRadius="5px" />
    <Flex flexDir="column" px="10px" py="5px" flex="1">
      {item?.titulo && (
        <Text fontWeight="600" fontSize="16px" noOfLines={2}>
          {item?.titulo}
        </Text>
      )}
      {item?.descricao && (
        <Text fontWeight="400" fontSize="12px">
          {item?.descricao}
        </Text>
      )}
      <Flex
        justify={isFilter ? "space-between" : "flex-end"}
        mt={isFilter ? "10px" : "0px"}
      >
        {isFilter && item?.data && (
          <Flex align="center" alignSelf="flex-end">
            <Icon as={AiOutlineCalendar} fontSize="12px" mr="5px" />
            <Text fontWeight="bold" fontSize="10px">
              {item?.data}
            </Text>
          </Flex>
        )}
        {item?.hora && (
          <Flex align="center" alignSelf="flex-end">
            <Icon as={AiOutlineClockCircle} fontSize="10px" mr="5px" />
            <Text fontWeight="400" fontSize="10px">
              {item?.hora}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  </Flex>
);

export default Home;
