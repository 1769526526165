import React from "react";

import { Switch, Route } from "react-router-dom";

import Home from "../pages/Home";
import Import from "../pages/Import";

function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/home">
        <Home />
      </Route>
      <Route exact path="/adm/import">
        <Import />
      </Route>
      <Route path="*">
        <Home />
      </Route>
    </Switch>
  );
}

export default Routes;
