import React from "react";

import { Flex } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "./routes";

function App() {
  return (
    <Router>
      <Flex flex="1" flexDir="column" minH="100vh">
        <Routes />
      </Flex>
    </Router>
  );
}

export default App;
