import React from "react";

import { Flex, CircularProgress } from "@chakra-ui/react";

function Loading() {
  return (
    <Flex flex="1" align="center" justify="center">
      <CircularProgress isIndeterminate color="blue.300" />
    </Flex>
  );
}

export default Loading;
