import React, { useState, useRef } from "react";

import {
  Flex,
  Input,
  Text,
  Button,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { saveAs } from "file-saver";
import {
  addDoc,
  collection,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useHistory } from "react-router-dom";

import { Header } from "../../components";
import { db } from "../../services/firebase";

import modelo from "./modelo.txt";

function Import() {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const inputRef = useRef();
  const [data, setData] = useState();

  const handleFile = (e) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", (event) => {
      try {
        const data = JSON.parse(event.target.result);
        console.log(data);
        setData(data);
      } catch (error) {
        inputRef.current.value = null;
        setData();
        toast({
          description: "Arquivo inválido",
          status: "error",
          isClosable: true,
          position: "top",
        });
      }
    });
    reader.readAsText(e.target.files[0]);
  };

  const handleSendData = async (sobrescrever) => {
    if (sobrescrever) {
      const querySnapshot = await getDocs(collection(db, "agenda"));
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.id);
      });
      Promise.all(
        list.map(async (item) => {
          try {
            await deleteDoc(doc(db, "agenda", item));
          } catch (error) {}
        })
      );
    }

    Promise.all(
      data.map(async (item) => {
        try {
          await addDoc(collection(db, "agenda"), item);
        } catch (e) {}
      })
    );
    inputRef.current.value = null;
    setData();
    onClose();
    toast({
      description: "Enviado com sucesso",
      status: "success",
      isClosable: true,
      position: "top",
    });
    history.push("/");
  };

  return (
    <Flex flex="1" flexDir="column">
      <Header />
      <Flex
        flex="1"
        px={["2", "5"]}
        py="2"
        flexDir="column"
        pb={["100px", "20px"]}
      >
        <Flex flexDir="column" flex="1">
          <Text fontSize="14px">Arquivo de importação</Text>
          <Input
            ref={inputRef}
            type="file"
            size="xs"
            accept=".json"
            multiple={false}
            onChange={handleFile}
          />
        </Flex>
        <Flex flexDir="row" justify="space-between">
          <Button
            size="xs"
            variant="ghost"
            onClick={() => {
              saveAs(modelo, "modelo.json");
            }}
          >
            Baixar modelo
          </Button>
          <Button
            size="xs"
            colorScheme="blue"
            minW="150px"
            onClick={onOpen}
            isDisabled={!data}
          >
            Enviar
          </Button>
        </Flex>

        <AlertDialog isOpen={isOpen} onClose={onClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Enviar dados
              </AlertDialogHeader>

              <AlertDialogBody>Deseja sobrescrever os dados?</AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={() => handleSendData(true)} size="sm">
                  Sobrescrever
                </Button>
                <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() => handleSendData(false)}
                  ml={3}
                >
                  Acrescentar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex>
    </Flex>
  );
}

export default Import;
