import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    heading: "Open Sans",
    body: "Open Sans",
  },
  styles: {
    global: {
      body: {
        bg: "white.700",
        color: "black.800",
        display: "standalone",
      },
    },
  },
});
