import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import {
  Flex,
  Text,
  Image,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { HiOutlineFilter } from "react-icons/hi";
import { collection, getDocs } from "firebase/firestore";

import Loading from "./Loading";

import logoImg from "../assets/images/logo.png";
import notFoundImg from "../assets/images/not_found.png";

import { db } from "../services/firebase";

function Header({ onFilter = () => {}, isFilter }, ref) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [usersSelected, setUsersSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [dataList, setDataList] = useState([]);

  useImperativeHandle(ref, () => ({
    clearFilter: () => {
      setUsersSelected([]);
      setUsers([]);
      setDataList([]);
    },
  }));

  useEffect(() => {
    setUsers([]);
    loadList();
  }, [isOpen]);

  const loadList = async () => {
    setLoading(true);
    const querySnapshot = await getDocs(collection(db, "agenda"));
    const newList = [];
    const list = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      (data?.usuarios || []).forEach((user) => {
        if (!list.find((item) => user === item)) {
          list.push(user);
        }
      });
      newList.push({ id: doc.id, ...data });
    });
    setLoading(false);
    setDataList(newList);
    setUsers(list);
  };

  const handleFilter = () => {
    onFilter(
      dataList.filter((item) => {
        let controler = false;
        (item?.usuarios || []).forEach((item) => {
          if (usersSelected.find((obj) => obj === item)) {
            controler = true;
          }
        });
        return controler;
      })
    );
  };

  return (
    <Flex
      ref={ref}
      align="center"
      justify="center"
      py="10px"
      bg="white"
      boxShadow="md"
      px="10px"
    >
      <Flex
        align="center"
        justify={isFilter ? "flex-start" : "center"}
        flex="1"
      >
        <Image src={logoImg} h="35px" mr="10px" objectFit="contain" />
        <Text
          fontWeight="800"
          fontStyle="italic"
          fontSize="26px"
          color="#3D488C"
        >
          Deus é amor
        </Text>
      </Flex>
      {isFilter && (
        <Icon as={HiOutlineFilter} fontSize="26px" onClick={onOpen} />
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filtrar</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="300px" overflowY="auto">
            {loading && <Loading />}
            {!loading && users.length > 0 && (
              <Wrap>
                {users.map((item, index) => (
                  <WrapItem key={index}>
                    <Button
                      size="xs"
                      onClick={() => {
                        if (usersSelected.find((obj) => item === obj)) {
                          setUsersSelected([
                            ...usersSelected.filter((obj) => item !== obj),
                          ]);
                        } else {
                          setUsersSelected([...usersSelected, item]);
                        }
                      }}
                      colorScheme={
                        usersSelected.find((obj) => item === obj)
                          ? "green"
                          : "gray"
                      }
                    >
                      {item}
                    </Button>
                  </WrapItem>
                ))}
              </Wrap>
            )}
            {!loading && users.length === 0 && (
              <Flex flex="1" align="center" justify="center" flexDir="column">
                <Image src={notFoundImg} boxSize="150px" />
                <Text textAlign="center" fontWeight="600" mt="15px">
                  Nenhum filtro disponível
                </Text>
              </Flex>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose} size="xs">
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              size="xs"
              onClick={() => {
                onClose();
                handleFilter();
              }}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default forwardRef(Header);
