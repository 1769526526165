import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const config = {
  apiKey: "AIzaSyBE8FFALBNc_vlykWqWqmmSgUDrX-J1m4M",
  authDomain: "escala-igreja-68577.firebaseapp.com",
  databaseURL: "https://escala-igreja-68577-default-rtdb.firebaseio.com",
  projectId: "escala-igreja-68577",
  storageBucket: "escala-igreja-68577.appspot.com",
  messagingSenderId: "59194774521",
  appId: "1:59194774521:web:afc2d57628740df34e6328",
};
export const app = initializeApp(config);
export const db = getFirestore(app);
